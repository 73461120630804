<template>
  <div @mouseleave="changeHoverFocus(this.selectedIndex)">
    <h2>{{ this.title }}</h2>
    <div class="newGreyTitle" style="padding: 5px">powered by Simons-1.2</div>
    <div class="flexSpaceBetweenRow" style="width: 829px">
      <div
        v-for="(d, index) of data"
        :key="index"
        @click="changeCandleFocus(index)"
        @mouseover="changeHoverFocus(index)"
        class="candleHoverBox"
      >
        <div
          class="candleHoverScreen"
          :class="{ selectedCandleScreen: this.hoverFocus == index }"
        >
          <span style="color: green; padding: 3px">
            <Icon
              img="arrow_full_up"
              hexCode="008000"
              size="6"
              style="margin-right: 1px"
            />
            <span>{{ Math.round(d[0]) }}%</span>
          </span>
          <span style="color: red; padding: 3px">
            <Icon
              img="arrow_full_down"
              hexCode="FF0000"
              size="6"
              style="margin-right: 1px"
            />
            <span>{{ Math.round(d[1]) }}%</span>
          </span>
        </div>
        <div
          class="candleBar"
          :class="{ selectedCandle: this.selectedIndex == index }"
        >
          <div
            class="candleTail"
            :style="{ 'height': `calc(100% - ${d[0]}%)` }"
          ></div>
          <div class="greenCandle" :style="{ 'height': `${d[0]}%` }"></div>
          <div class="redCandle" :style="{ 'height': `${d[1]}%` }"></div>
          <div
            class="candleTail"
            :style="{ 'height': `calc(100% - ${d[1]}%)` }"
          ></div>
          <div style="margin-top: 10px">{{ labels[index] }}</div>
        </div>
        <!-- {{ d[0] }}, {{ d[1] }} -->
      </div>
    </div>
    <!-- <div v-for="l of labels" :key="l">hi {{ l }}</div> -->
  </div>
</template>

<script>
import Icon from "@/components/utilities/Icon.vue";

export default {
  name: "CandleGraph",
  props: {
    labels: Array,
    data: Array,
    title: String,
    // selectedIndex: Number,
  },
  components: {
    Icon,
  },
  data() {
    return {
      selectedIndex: this.labels.length - 1,
      hoverFocus: this.labels.length - 1,
    };
  },
  methods: {
    changeCandleFocus(i) {
      this.selectedIndex = i;
      this.$emit("changeCandleFocus", i);
    },
    changeHoverFocus(i) {
      this.hoverFocus = i;
    },
    getMax(d, i) {
      let max = 0;
      for (let item of d) {
        if (item[i] > max) max = item;
      }
      return max;
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
.candleBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 200px;
  border-radius: 7px;
  margin: 3px;
  max-width: 71px !important;
}
.selectedCandle {
  outline: thin solid #9595953f;
  background: #95959511;
}
.candleHoverBox {
  cursor: pointer;
}
.candleHoverBox:hover .candleBar {
  outline: thin solid #9595958c;
}
.candleTail {
  background: #9595953f;
  width: 1px;
}
.candleHoverBox:hover .candleTail {
  background: #9595958c;
}
.greenCandle {
  background: green;
  width: 9px;
  border-radius: 5px 5px 0 0;
}
.redCandle {
  background: red;
  width: 9px;
  border-radius: 0 0 5px 5px;
}
.candleHoverScreen {
  background: white;
  outline: thin solid #9595953f;
  border-radius: 7px;
  /* position: absolute; */
  /* height: 10px; */
  /* top: 0; */
  /* transform: translateY(-40px); */
  /* display: none; */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.candleHoverBox:hover .candleHoverScreen {
  opacity: 1;
}
.selectedCandleScreen {
  opacity: 1;
}
/* .candleHoverBox:hover .selectedCandleScreen {
  opacity: 0;
} */
</style>
