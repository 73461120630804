<template>
  <div id="personExpBox" class="boxShadow">
    <div class="paddedRow">
      <LightButton
        @click="closePersonBox()"
        text="Back to company"
        img="arrow_left"
        style="width: fit-content"
      />
    </div>
    <div class="paddedRow colouredRow">
      <img height="25" width="25" src="@/assets/images/emptyProfile.png" />
      <div style="margin: 0 10px">{{ this.person.name }}</div>
      <Tag :text="this.companyName" color="000000" img="building" />
    </div>
    <div class="flexCenterRow">
      <div class="experiencesColumn">
        <div class="paddedRow personNav">
          <NavButton
            v-for="(expType, index) of ['Work', 'Founding', 'Education']"
            :text="expType + ' Experiences'"
            :img="expImg[index]"
            style="width: fit-content"
            @click="showCurrentExp(expType)"
            :isSelected="currentExp == expType"
            :key="expType"
          />
          <!-- <NavButton
            text="Founding Experiences"
            img="building"
            style="width: fit-content"
            @click="showCurrentExp('founding')"
            :isSelected="currentExp == 'founding'"
          />
          <NavButton
            text="Education Experiences"
            img="building"
            style="width: fit-content"
            @click="showCurrentExp('education')"
            :isSelected="currentExp == 'education'"
          /> -->
        </div>
        <div v-if="currentExp == 'Work'" class="scrollExp">
          <Experience
            v-for="(exp, index) of this.person.work_experience"
            :companyName="exp.company.name"
            :positionTitle="exp.position_title"
            :positionDescription="exp.position_description"
            :startYear="exp.start_year"
            :yearsWorked="exp.years_worked"
            :linkedinUrl="exp.company.linkedin_url"
            :key="index"
          />
          <div
            v-if="this.person.work_experience.length == 0"
            style="padding: 40px; color: #636363; text-align: center"
          >
            No work experiences
          </div>
        </div>
        <div v-if="currentExp == 'Founding'">
          <Experience
            v-for="(exp, index) of this.person.founding_experience"
            :companyName="exp.company.name"
            positionTitle="Founder"
            :positionDescription="exp.company.tagline"
            :startYear="exp.start_year"
            :yearsWorked="0"
            :linkedinUrl="exp.company.linkedin_url"
            :key="index"
          />
          <div
            v-if="this.person.founding_experience.length == 0"
            style="padding: 40px; color: #636363; text-align: center"
          >
            No founding experiences
          </div>
        </div>
        <div v-if="currentExp == 'Education'">
          <span
            v-for="(exp, index) of this.person.education_experience"
            :key="index"
          >
            <Experience
              v-if="exp.school.world_rank != 9999"
              :companyName="exp.school.name"
              :positionTitle="exp.degree_name"
              :positionDescription="exp.field_of_study"
              :linkedinUrl="exp.school.linkedin_url"
              :dateRange="exp.date_range"
            />
          </span>
          <div
            v-if="this.person.education_experience.length == 0"
            style="padding: 40px; color: #636363; text-align: center"
          >
            No education experiences
          </div>
        </div>
      </div>
      <div class="recapColumn">
        <!-- {{ this.recap }} -->
        <div class="newGreyTitle" style="padding: 10px">Founder recap</div>
        <div v-for="(r, index) of this.recap.exp_recap" :key="index">
          <!-- {{ expImg[index] }} -->
          <Tag
            v-if="r"
            :text="r"
            color="000000"
            :img="expImg[index]"
            style="margin: 10px"
          />
        </div>
        <div class="newGreyTitle" style="padding: 10px">
          Most notable insights
        </div>
        <div v-for="(i, index) of this.recap.exp_insights" :key="index">
          <Tag
            v-if="i"
            :text="i"
            color="721cff"
            img="sparks"
            style="margin: 10px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase";
// import db from "@/main.js";
// import { ref } from "vue";
import LightButton from "@/components/buttons/LightButton.vue";
import NavButton from "@/components/buttons/NavButton.vue";
import Tag from "@/components/utilities/Tag.vue";
import Experience from "@/components/designs/Experience.vue";
// import Education from "@/components/utilities/Education.vue";

export default {
  name: "Person",
  components: {
    LightButton,
    NavButton,
    Tag,
    Experience,
    // Education,
  },
  props: {
    // showPerson: Boolean,
    person: Object,
    recap: Object,
    companyName: String,
  },
  data() {
    // console.log(this.recap);
    return {
      currentExp: "Work",
      expImg: ["building", "plant", "student_hat"],
      //   person: this.person,
    };
  },
  methods: {
    closePersonBox() {
      this.$emit("closePersonBox");
    },
    showCurrentExp(clickedOn) {
      this.currentExp = clickedOn;
    },
    // onEscapeKeyUp(event) {
    //   console.log(event);
    //   if (event.which === 27) {
    //     this.closePersonBox();
    //   }
    // },
  },
  setup() {
    return {};
  },
  watch: {
    // showPerson() {
    //   console.log("hello");
    //   if (this.showPerson === false) {
    //     window.removeEventListener("keyup", this.onEscapeKeyUp);
    //   } else {
    //     window.addEventListener("keyup", this.onEscapeKeyUp);
    //   }
    // },
  },
};
</script>

<style scoped>
#personExpBox {
  top: 12px;
  bottom: 12px;
  right: 12px;
  left: 262px;
  background: white;
  position: fixed;
  border-radius: 5px;
  background: white;
}
.paddedRow {
  padding: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.personNav {
  border-bottom: thin solid #9595953f;
  padding-bottom: 0;
  padding-top: 3px;
  /* position: sticky; */
  top: 0;
  background: white;
}
.colouredRow {
  background: #fafafa;
  border-top: thin solid #9595953f;
  border-bottom: thin solid #9595953f;
  width: calc(100% - 14px);
}
.experiencesColumn {
  width: 70%;
  border-right: thin solid #9595953f;
  height: calc(100vh - 121px);
  max-width: 800px;
}
.scrollExp {
  height: calc(100% - 45px);
  overflow: scroll;
}
.recapColumn {
  width: 30%;
  height: calc(100vh - 119px);
  max-width: 300px;
}
.title {
  font-weight: bold;
  padding: 7px;
}
</style>
