<template>
  <div id="termsBody">
    <!-- <div class="tcTitle">Moonarch Inc. Terms and Conditions</div> -->
    <div class="tcText">
      Please read these terms and conditions ("Terms") carefully before using
      the Moonarch Inc. website or any services provided by Moonarch Inc. These
      Terms apply to all visitors, users, and others who access or use Moonarch
      Inc. services ("Users").
    </div>
    <div class="tcSubtitle">1. Acceptance of terms</div>
    <div class="tcText">
      By accessing or using Moonarch Inc., you agree to be bound by these Terms.
      If you disagree with any part of these Terms, you may not access Moonarch
      Inc. or use any of its services.
    </div>
    <div class="tcSubtitle">2. Changes to the Terms</div>
    <div class="tcText">
      Moonarch Inc. reserves the right, at its sole discretion, to modify or
      replace these Terms at any time. It is your responsibility to review these
      Terms periodically for changes. Continued use of Moonarch Inc. after any
      modifications to the Terms constitutes acceptance of those changes.
    </div>
    <div class="tcSubtitle">3. User Accounts</div>
    <div class="tcText">
      Some services provided by Moonarch Inc. may require you to create an
      account. You are responsible for maintaining the confidentiality of your
      account and password and for restricting access to your computer or
      device. You agree to accept responsibility for all activities that occur
      under your account.
    </div>
    <div class="tcSubtitle">4. Intellectual Property</div>
    <div class="tcText">
      The content, features, and functionality of Moonarch Inc., including but
      not limited to text, graphics, logos, images, and software, are the
      intellectual property of Moonarch Inc. and its licensors. You may not
      copy, modify, distribute, sell, or lease any part of Moonarch Inc. without
      prior written consent from Moonarch Inc.
    </div>
    <div class="tcSubtitle">5. Disclaimer of Warranties</div>
    <div class="tcText">
      Moonarch Inc. is provided on an "as is" and "as available" basis without
      warranties of any kind, whether expressed or implied. Moonarch Inc. does
      not guarantee the accuracy, reliability, or availability of its services.
    </div>
    <div class="tcSubtitle">6. Limitation of Liability</div>
    <div class="tcText">
      In no event shall Moonarch Inc. be liable for any indirect, incidental,
      special, consequential, or punitive damages, including but not limited to
      loss of profits, data, or use, arising out of or in connection with the
      use of Moonarch Inc. services.
    </div>
    <div class="tcSubtitle">7. Severability</div>
    <div class="tcText">
      If any provision of these Terms is found to be unenforceable or invalid
      under any applicable law, such unenforceability or invalidity shall not
      render these Terms unenforceable or invalid as a whole.
    </div>
    <div class="tcSubtitle">8. Entire Agreement</div>
    <div class="tcText">
      These Terms constitute the entire agreement between Moonarch Inc. and you
      regarding the use of Moonarch Inc. services and supersede all prior
      agreements and understandings.
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsConditions",
  components: {},
  methods: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
#termsBody {
  width: 100%;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  font-family: sans-serif;
}
.tcTitle {
  font-weight: bold;
  font-size: 20px;
  margin: 20px;
}
.tcSubtitle {
  font-weight: bold;
  font-size: 15px;
  margin: 20px;
}
.tcText {
  font-size: 15px;
  margin: 20px;
}
</style>
