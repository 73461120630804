<template>
  <div v-if="this.searchResults.includes('LOADING')" class="loadingOutline">
    <div class="loadingLine"></div>
  </div>
  <div
    v-if="!this.searchResults.includes('LOADING')"
    class="greyTitle"
    style="width: 872px; text-align: left; margin: 20px 0"
  >
    Showing results for: {{ this.searchCopy }}
  </div>
  <div
    v-if="this.searchResults.includes('LOADING')"
    class="greyTitle"
    style="width: 872px; text-align: left; margin: 20px 0"
  >
    Searching for: {{ this.searchCopy }}
  </div>
  <div class="flexLeftRow" style="width: 872px">
    <div class="greyTitle" style="width: 190px">Name</div>
    <div class="greyTitle" style="width: 140px">Stage</div>
    <div class="greyTitle" style="width: 190px">Industry</div>
    <div class="greyTitle" style="width: 140px">Location</div>
    <div class="greyTitle" style="width: 140px">Date Founded</div>
    <!-- <div class="greyTitle" style="width: 200px">Founders</div> -->
  </div>
  <div
    v-for="(result, index) in this.searchResults"
    :key="index"
    @click="clickedOnStartup(index, result, false)"
  >
    <div class="resultListItem" v-if="result == 'LOADING'">
      <div class="resultItem" style="width: 178px; padding-left: 0">
        <div class="loadingTextItem"></div>
      </div>
      <div class="resultItem" style="width: 143px; padding-left: 0">
        <div class="loadingTextItem"></div>
      </div>
      <div class="resultItem" style="width: 188px; padding-left: 0">
        <div class="loadingTextItem"></div>
      </div>
      <div class="resultItem" style="width: 143px; padding-left: 0">
        <div class="loadingTextItem"></div>
      </div>
      <div
        class="resultItem"
        style="width: 142px; padding-left: 0; border-right: none"
      >
        <div class="loadingTextItem"></div>
      </div>
    </div>
    <div
      v-if="result != 'LOADING'"
      class="resultListItem"
      :class="[
        this.validateStartup(result) > 0 ? 'orangeNotif' : '',
        this.validateStartup(result) >= 10 ? 'redNotif notAllowed' : '',
      ]"
    >
      <div class="resultItem" style="width: 200px; padding-left: 0">
        <!-- <div class="suggestedLogo"></div> -->
        <img :src="result.logo_url" class="suggestedLogo" />
        <div class="resultText">{{ result.name }}</div>
      </div>
      <div class="resultItem" style="width: 150px">
        <div class="resultFounder" v-if="result.stage">
          {{ this.stage[result.stage] }}
        </div>
        <div class="resultFounder" v-else>-</div>
      </div>
      <div class="resultItem" style="width: 200px">
        <!-- validateIndustry -->
        <div
          v-if="validateIndustry(result.industry_simple) > 0"
          style="height: 17px; margin-right: 5px"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="17"
            height="17"
            color="orange"
            fill="none"
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="1.5"
            />
            <path
              d="M11.992 15H12.001"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 12L12 8"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          class="resultFounder"
          v-if="result.industry_simple && result.industry_simple[0]"
        >
          {{ result.industry_simple[0] }}
        </div>
        <div class="resultFounder" v-else>-</div>
      </div>
      <div class="resultItem" style="width: 150px">
        <div v-if="validateLocation(result.location) > 0" style="height: 17px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="17"
            height="17"
            color="orange"
            fill="none"
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="1.5"
            />
            <path
              d="M11.992 15H12.001"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 12L12 8"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          class="resultFounder"
          v-if="
            result.location &&
            result.location[0] &&
            result.location[0].fullAddress
          "
          style="margin-left: 5px"
        >
          {{ result.location[0].fullAddress.split(",").slice(-1).pop() }}
        </div>
        <div class="resultFounder" style="margin-left: 5px" v-else>unknown</div>
      </div>
      <div class="resultItem" style="width: 150px; border-right: transparent">
        <div v-if="validateYear(result.year_founded) > 0" style="height: 17px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="17"
            height="17"
            color="orange"
            fill="none"
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="1.5"
            />
            <path
              d="M11.992 15H12.001"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 12L12 8"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          v-if="result.year_founded && result.linkedin_id != 93605691"
          style="margin-left: 5px"
        >
          {{ result.year_founded }}
        </div>
        <div
          v-else-if="result.year_founded && result.linkedin_id == 93605691"
          style="margin-left: 5px"
        >
          2024
        </div>
        <div v-else style="margin-left: 5px">unknown date</div>
      </div>
      <div class="hoverButton buttonBlue">
        <span style="margin-right: 10px">Evaluate Company</span>
        <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
          fill="white"
        >
          <path
            d="M212.309-140.001q-30.308 0-51.308-21t-21-51.308v-535.382q0-30.308 21-51.308t51.308-21h535.382q30.308 0 51.308 21t21 51.308v37.692q0 12.769-8.616 21.384Q802.768-680 789.999-680t-21.384-8.615Q760-697.23 760-709.999v-37.692q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H212.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v535.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h535.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-37.692q0-12.769 8.615-21.384T789.999-280q12.769 0 21.384 8.615 8.616 8.615 8.616 21.384v37.692q0 30.308-21 51.308t-51.308 21H212.309Zm532.769-310H397.692q-12.769 0-21.384-8.615T367.693-480q0-12.769 8.615-21.384t21.384-8.615h347.386l-91.924-93.309q-8.308-8.308-8.615-20.384-.308-12.077 8.615-21 8.307-8.307 20.884-8.307t20.884 8.307l139.769 139.385q5.615 5.615 7.923 11.846 2.308 6.23 2.308 13.461t-2.308 13.461q-2.308 6.231-7.923 11.846L694.922-314.924q-8.307 8.308-20.576 8.423-12.269.116-21.192-8.807-8.308-8.308-8.308-20.692t8.308-20.692l91.924-93.309Z"
          />
        </svg> -->
        <svg
          v-if="loadingstage == 0"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          color="white"
          fill="none"
        >
          <path
            d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
            stroke="currentColor"
            stroke-width="1.5"
          />
          <path
            d="M16 12H8M16 12C16 11.2998 14.0057 9.99153 13.5 9.5M16 12C16 12.7002 14.0057 14.0085 13.5 14.5"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="turningAnimation" v-if="loadingstage != 0">
          <svg width="20" height="20" viewBox="0 0 97 97" fill="none">
            <path
              d="M7 48.5C7 25.5802 25.5802 7 48.5 7"
              stroke="grey"
              stroke-width="10"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div v-if="loadingstage != 0" class="flexLeftRow" style="padding: 10px">
    <div class="turningAnimation">
      <svg width="20" height="20" viewBox="0 0 97 97" fill="none">
        <path
          d="M7 48.5C7 25.5802 25.5802 7 48.5 7"
          stroke="grey"
          stroke-width="10"
          stroke-linecap="round"
        />
      </svg>
    </div>
    <div style="margin-left: 10px">loading...</div>
  </div>
  <div v-if="loadingstage == 0" class="flexLeftRow" style="margin: 10px 0">
    <div
      class="resultFounder"
      style="
        width: fit-content;
        font-size: 13px;
        height: 20px;
        display: flex;
        align-items: center;
      "
    >
      <span style="margin: 0 10px"> Evaluation will be optimized </span>
    </div>
    <div
      class="resultFounder notifOutline"
      style="width: fit-content; font-size: 13px; height: 20px"
    >
      <span style="margin: 0 10px"> Evaluation results may vary </span>
    </div>
    <div
      class="resultFounder notifOutline redNotif"
      style="width: fit-content; font-size: 13px; height: 20px"
    >
      <span style="margin: 0 10px">Evaluation cannot be run</span>
    </div>
  </div>
  <div
    v-if="this.validateWarning"
    class="popupBack"
    @click="cancelClick()"
  ></div>
  <!-- <div class="popup" v-if="this.validateWarning"> -->
  <div class="popupBody" v-if="this.validateWarning">
    <div class="warningTitle">Warning</div>
    <div class="warningText">
      {{ this.validateWarningCompany.name }} has unsupported metrics. Results
      may be unreliable.
    </div>
    <div class="deleteActions">
      <div
        class="buttonLight2"
        style="margin-right: 5px"
        @click="cancelClick()"
      >
        Cancel
      </div>
      <div
        class="buttonBlue"
        @click="
          clickedOnStartup(
            this.validateWarningIndex,
            this.validateWarningCompany,
            true
          )
        "
      >
        <div
          class="turningAnimation"
          v-if="loadingstage != 0"
          style="margin-right: 20px"
        >
          <svg width="20" height="20" viewBox="0 0 97 97" fill="none">
            <path
              d="M7 48.5C7 25.5802 25.5802 7 48.5 7"
              stroke="white"
              stroke-width="10"
              stroke-linecap="round"
            />
          </svg>
        </div>
        Evaluate Company
      </div>
    </div>
    <!-- <div class="warningText">
      Luna 1.2 cannot correctly evaluate startups with:
    </div>
    <div v-if="validateYear(this.validateWarningCompany.year_founded) > 0">
      <div v-if="this.validateWarningCompany.year_founded">
        {{ this.validateWarningCompany.year_founded }}
      </div>
      <div v-else>unknown year founded</div>
    </div>
    <div
      v-if="validateIndustry(this.validateWarningCompany.industry_simple) > 0"
    >
      <div
        v-if="
          this.validateWarningCompany.industry_simple &&
          this.validateWarningCompany.industry_simple[0]
        "
      >
        Industry: {{ this.validateWarningCompany.industry_simple[0] }}
      </div>
      <div v-else>unknown industry</div>
    </div> -->
  </div>
  <!-- </div> -->
</template>

<script>
// import firebase from "firebase";
// import axios from "axios";
import { db, auth } from "@/main.js";
import { getDocs, query, where, collection } from "@firebase/firestore";
import logAction from "@/trackers.js";
// import { ref } from "vue";

export default {
  name: "SearchResults",
  props: {
    searchResults: Object,
    lunaSearch: String,
    stage: Object,
    industryCipher: Object,
    aiCipher: Object,
    isAdmin: Boolean,
  },
  emits: {
    navLuna: null,
    openStartup: null,
  },
  data() {
    // let user = firebase.auth().currentUser;
    let user = auth.currentUser;

    return {
      user: user,
      loadingstage: 0,
      searchCopy: this.lunaSearch,
      validateWarning: false,
      validateWarningCompany: null,
      validateWarningIndex: null,
      // tempCount1: this.searchResults.filter((x) => x == "LOADING").length,
      // tempCount2: this.searchResults.filter((x) => x != "LOADING").length,
      // // currentScrapingText: this.searchResults.filter((x) => x == "LOADING")
      // //   .length,
      // currentScrapingText:
      //   this.searchResults.filter((x) => x == "LOADING").length <
      //   this.searchResults.filter((x) => x != "LOADING").length
      //     ? "Linkedin"
      //     : "Crunchbase",
    };
  },
  components: {},
  methods: {
    validateLocation(location) {
      if (
        location &&
        location[0] &&
        location[0].fullAddress &&
        location[0].fullAddress.split(",").slice(-1).pop() == " US"
      ) {
        return 0;
      } else if (
        location &&
        location[0] &&
        location[0].fullAddress &&
        location[0].fullAddress.split(",").slice(-1).pop() == " CA"
      ) {
        return 0;
      } else if (!location || !location[0] || !location[0].fullAddress) {
        return 1;
      } else {
        return 2;
      }
    },
    validateYear(year) {
      if (year && year >= 2015) {
        return 0;
      } else if (year && year == 2014) {
        return 1;
      } else if (!year) {
        return 1;
      } else {
        return 2;
      }
    },
    validateIndustry(industry) {
      if (
        industry &&
        industry[0] &&
        (industry[0] == "SaaS" || industry[0] == "AI")
      ) {
        return 0;
      } else if (
        industry &&
        industry[0] &&
        (industry[0] == "SaaS" ||
          industry[0] == "Other" ||
          industry[0] == "Real Estate")
      ) {
        return 1;
      } else {
        return 2;
      }
    },
    validateStartup(check) {
      // if (check.linkedin_id == "93605691") {
      //   return 10;
      // }

      let strikeCount = 0;

      strikeCount += this.validateLocation(check.location);
      strikeCount += this.validateYear(check.year_founded);
      strikeCount += this.validateIndustry(check.industry_simple);

      // if (!this.validateIndustry(check.industry_simple[0])) return 2;

      if (strikeCount == 0) {
        return 0;
      } else {
        return strikeCount;
      }
    },
    cancelClick() {
      this.validateWarning = false;
      this.validateWarningCompany = null;
      this.validateWarningIndex = null;
    },
    async clickedOnStartup(index, result, skipValidation) {
      if (this.validateStartup(result) >= 10) {
        // && !this.isAdmin
        return;
      } else if (this.validateStartup(result) >= 1 && !skipValidation) {
        this.validateWarning = true;
        this.validateWarningCompany = result;
        this.validateWarningIndex = index;
        return;
      }

      logAction(result.linkedin_url, "review luna info");

      this.loadingstage = 1;

      let alreadySearched = false;

      const results_ref = query(
        collection(db, "luna_results"),
        where("USER", "==", this.user.uid),
        where("LINKEDIN_ID", "==", this.searchResults[index].linkedin_id)
      );

      const luna_snapshot = await getDocs(results_ref);

      luna_snapshot.forEach((doc) => {
        if (!doc.data().DELETED && !doc.data().DEPRECATED) {
          this.$emit("navLuna", doc.data());
          alreadySearched = true;
        }
        if (alreadySearched) return;
      });

      // await db
      //   .collection("luna_results")
      //   .where("USER", "==", this.user.uid)
      //   .where("LINKEDIN_ID", "==", this.searchResults[index].linkedin_id)
      //   .get()
      //   .then((docs) => {
      //     docs.forEach((doc) => {
      //       if (!doc.data().DELETED && !doc.data().DEPRECATED) {
      //         this.$emit("navLuna", doc.data());
      //         alreadySearched = true;
      //       }
      //       if (alreadySearched) return;
      //       // this.navLuna(doc.data());
      //       // this.loadingstage -= 1;
      //     });
      //   });
      if (alreadySearched) return;

      this.validateWarning = false;
      this.validateWarningCompany = null;
      this.validateWarningIndex = null;

      this.$emit("openStartup", this.searchResults[index]);
    },
  },
  setup() {
    return {};
  },
};
</script>

<style></style>
