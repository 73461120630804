<template>
  <div id="personExpBox" class="boxShadow">
    <div class="paddedRow">
      <LightButton
        @click="closeTeam()"
        text="Back to company"
        img="arrow_left"
        style="width: fit-content"
      />
    </div>
    <div class="paddedRow colouredRow">
      <div style="margin: 0 10px">List of People in {{ this.chosenTeam }}</div>
    </div>
    <div class="tileWrap">
      <div class="tileList">
        <div v-for="p of this.sortedTeam" :key="p">
          <div
            v-if="
              p.currentPositions &&
              p.currentPositions[0] &&
              p.currentPositions[0].title
            "
            class="personTile"
          >
            <div
              style="
                border-radius: 50%;
                overflow: hidden;
                min-height: 40px;
                min-width: 40px;
                max-height: 40px;
                max-width: 40px;
              "
            >
              <img :src="p.profilePictureLink" height="40" width="40" />
            </div>
            <div style="margin-left: 5px">
              <div class="title" style="width: 200px">
                {{ p.fullName }}
              </div>
              <div
                style="
                  font-size: 14px;
                  padding: 4px 0;
                  width: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
                v-if="
                  p.currentPositions &&
                  p.currentPositions[0] &&
                  p.currentPositions[0].title
                "
              >
                {{ p.currentPositions[0].title }}
              </div>
              <div class="newGreyTitle">{{ p.geoRegion }}</div>
              <Tag
                :text="p.experienceLevel"
                :color="teamTagColour[teamOrder.indexOf(p.experienceLevel)]"
                img="person_card"
                style="margin-top: 10px"
              />
            </div>
            <LightButton
              @click="openLink(p.entityUrn)"
              img="linkedin_blue"
              icon_size="16"
              style="height: fit-content"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase";
// import db from "@/main.js";
// import { ref } from "vue";
// import Education from "@/components/utilities/Education.vue";
import LightButton from "@/components/buttons/LightButton.vue";
import Tag from "@/components/utilities/Tag.vue";

export default {
  name: "Person",
  components: {
    LightButton,
    Tag,
  },
  props: {
    // showPerson: Boolean,
    chosenTeam: String,
    chosenTeamList: Object,
  },
  data() {
    // console.log(this.recap);
    // console.log(this.chosenTeamList);
    const order = ["Executive", "Advisor", "Investor", "Intern", "Employee"];

    let sortedTeam = [];

    for (let p of this.chosenTeamList) {
      sortedTeam.push(p);
    }

    sortedTeam.sort(function (a, b) {
      return (
        order.indexOf(a.experienceLevel) - order.indexOf(b.experienceLevel)
      );
    });

    console.log(sortedTeam);

    return {
      currentExp: "Work",
      // expImg: ["building", "plant", "student_hat"],
      teamTagColour: ["721cff", "9B5EFF", "9B5EFF", "000000", "000000"],
      sortedTeam: sortedTeam,
      teamOrder: order,
      //   person: this.person,
    };
  },
  methods: {
    closeTeam() {
      this.$emit("closeTeam");
    },
    openLink(urn) {
      window.open("https://linkedin.com/in/" + urn, "_blank");
    },
    // onEscapeKeyUp(event) {
    //   console.log(event);
    //   if (event.which === 27) {
    //     this.closePersonBox();
    //   }
    // },
  },
  setup() {
    return {};
  },
  watch: {
    // showPerson() {
    //   console.log("hello");
    //   if (this.showPerson === false) {
    //     window.removeEventListener("keyup", this.onEscapeKeyUp);
    //   } else {
    //     window.addEventListener("keyup", this.onEscapeKeyUp);
    //   }
    // },
  },
};
</script>

<style scoped>
#personExpBox {
  top: 12px;
  bottom: 12px;
  right: 12px;
  left: 262px;
  background: white;
  position: fixed;
  border-radius: 5px;
  background: white;
}
.paddedRow {
  padding: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.personNav {
  border-bottom: thin solid #9595953f;
  padding-bottom: 0;
  padding-top: 3px;
  /* position: sticky; */
  top: 0;
  background: white;
}
.colouredRow {
  background: #fafafa;
  border-top: thin solid #9595953f;
  border-bottom: thin solid #9595953f;
}
.experiencesColumn {
  width: 70%;
  border-right: thin solid #9595953f;
  height: calc(100vh - 121px);
  max-width: 800px;
}
.scrollExp {
  height: calc(100% - 45px);
  overflow: scroll;
}
.recapColumn {
  width: 30%;
  height: calc(100vh - 119px);
  max-width: 300px;
}
.title {
  font-weight: bold;
}
.tileWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tileList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 1000px;
  /* max-height: 718px; */
  max-height: calc(100vh - 130px);
  overflow: scroll;
  padding-top: 20px;
}
.personTile {
  border: thin solid #9595953f;
  border-radius: 7px;
  padding: 10px;
  width: 300px;
  height: 95px;
  /* height: 58px; */
  margin: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
