<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="width"
    :height="height"
    :color="hexCode"
    fill="none"
  >
    <path
      d="M2 22H22"
      stroke="currentColor"
      :stroke-width="stroke"
      stroke-linecap="round"
    />
    <path
      d="M18 9H14C11.518 9 11 9.518 11 12V22H21V12C21 9.518 20.482 9 18 9Z"
      stroke="currentColor"
      :stroke-width="stroke"
      stroke-linejoin="round"
    />
    <path
      d="M15 22H3V5C3 2.518 3.518 2 6 2H12C14.482 2 15 2.518 15 5V9"
      stroke="currentColor"
      :stroke-width="stroke"
      stroke-linejoin="round"
    />
    <path
      d="M3 6H6M3 10H6M3 14H6"
      stroke="currentColor"
      :stroke-width="stroke"
      stroke-linecap="round"
    />
    <path
      d="M15 13H17M15 16H17"
      stroke="currentColor"
      :stroke-width="stroke"
      stroke-linecap="round"
    />
    <path
      d="M16 22L16 19"
      stroke="currentColor"
      :stroke-width="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: "building",
  props: {
    height: Number,
    width: Number,
    hexCode: String,
    stroke: String,
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
  setup() {
    return {};
  },
};
</script>

<style></style>
