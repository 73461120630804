<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="width"
    :height="height"
    :color="hexCode"
    fill="none"
  >
    <path
      d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18"
      stroke="currentColor"
      :stroke-width="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: "statistics",
  props: {
    height: Number,
    width: Number,
    hexCode: String,
    stroke: String,
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
  setup() {
    return {};
  },
};
</script>

<style></style>
