<template>
  <div class="expItem">
    <div
      class="flexSpaceBetweenRow"
      style="width: 100%; margin-bottom: 10px; width: 780px"
    >
      <div>
        <div class="flexLeftRow">
          <div>
            <img
              height="25"
              width="25"
              src="@/assets/images/emptyCompany.png"
            />
          </div>
          <div style="margin-left: 20px">
            <div
              style="
                font-size: 16px;
                margin-bottom: 5px;
                text-decoration: underline;
              "
            >
              {{ companyName }}
            </div>
            <div style="font-size: 13px; margin-bottom: 5px; color: #636363">
              {{ positionTitle }}
            </div>
          </div>
        </div>
      </div>
      <div class="flexRightRow">
        <div style="color: grey; font-size: 14px; margin-right: 15px">
          {{ datesWorked }}
        </div>
        <LightButton
          @click="openLink(linkedinUrl)"
          img="linkedin_blue"
          style="width: fit-content"
        />
      </div>
    </div>
    <div
      v-if="positionDescription"
      style="padding: 10px 45px 0 45px; font-size: 14px"
    >
      {{ positionDescription }}
    </div>
  </div>
</template>

<script>
// import firebase from "firebase";
// import db from "@/main.js";
import LightButton from "@/components/buttons/LightButton.vue";
// import { ref } from "vue";

export default {
  name: "Person",
  components: {
    LightButton,
  },
  props: {
    // experience: Object,
    companyName: String,
    positionTitle: String,
    positionDescription: String,
    startYear: Number,
    yearsWorked: Number,
    linkedinUrl: String,
    dateRange: String,
  },
  data() {
    // const abbreviatedMonths = [
    //   "Jan",
    //   "Feb",
    //   "Mar",
    //   "Apr",
    //   "May",
    //   "Jun",
    //   "Jul",
    //   "Aug",
    //   "Sep",
    //   "Oct",
    //   "Nov",
    //   "Dec",
    // ];

    // let startingDate =
    //   abbreviatedMonths[this.experience.start_month - 1] +
    //   " " +
    //   this.experience.start_year;
    let startingDate = this.startYear;

    // let endingDate =
    //   abbreviatedMonths[
    //     this.getEndingMonth(
    //       this.experience.start_month,
    //       this.getDecimalPart(this.experience.years_worked)
    //     )
    //   ] +
    //   " " +
    //   Math.floor(this.experience.start_year + this.experience.years_worked);
    let endingDate = Math.floor(this.startYear + this.yearsWorked);
    console.log(this.yearsWorked);

    let datesWorked = "";

    console.log(this.dateRange);

    if (this.dateRange) {
      datesWorked = this.dateRange;
    } else {
      datesWorked = startingDate;
      console.log(endingDate);

      if (endingDate && endingDate != startingDate) {
        datesWorked += " - " + endingDate;
      }
    }
    return {
      datesWorked: datesWorked,
    };
  },
  methods: {
    openLink(link) {
      if (!link.includes("https://")) {
        link = "https://" + link;
      }
      window.open(link, "_blank");
    },
    getDecimalPart(number) {
      // Ensure the number is a float
      if (typeof number !== "number" || !Number.isFinite(number)) {
        throw new TypeError("Input must be a finite number.");
      }

      // Subtract the integer part from the number
      const integerPart = Math.floor(number);
      const decimalPart = number - integerPart;

      // Return the decimal part
      return decimalPart;
    },
    getEndingMonth(startMonth, yearsWorked) {
      // Ensure startMonth is between 0 and 11, and yearsWorked is a positive number
      if (startMonth < 1 || startMonth > 12 || yearsWorked < 0) {
        throw new Error(
          "Invalid input: startMonth must be between 0 and 11, and yearsWorked must be non-negative."
        );
      }

      // Calculate total number of months worked
      const totalMonthsWorked = Math.round(yearsWorked * 12);
      console.log(totalMonthsWorked);

      // Calculate the ending month
      const endingMonth = ((startMonth + totalMonthsWorked) % 12) - 1;
      console.log(endingMonth);

      return endingMonth;
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
.expItem {
  padding: 10px;
  /* display: flex; */
}
</style>
