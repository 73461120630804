<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 36 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6699 2.5C15.5944 -0.833332 20.4056 -0.833334 22.3301 2.5L35.3205 25C37.245 28.3333 34.8394 32.5 30.9904 32.5H5.00962C1.16062 32.5 -1.24501 28.3333 0.679492 25L13.6699 2.5Z"
      fill="#008000"
    />
  </svg>
</template>
<script>
export default {
  name: "arrow_full_up",
  props: {
    height: Number,
    width: Number,
    hexCode: String,
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
  setup() {
    return {};
  },
};
</script>

<style></style>
