// import db from "@/main.js";
// import auth from "@/main.js";
import { getAuth } from "@firebase/auth";
import { getFirestore, collection, doc, setDoc } from '@firebase/firestore';
// import firebase from "firebase";

export default function logAction(entity, action) {
    const db = getFirestore();
    const auth = getAuth();
    var timestamp = new Date();
    const db_tracking = collection(db, "tracking");
    if (auth.currentUser) {
        setDoc(doc(db_tracking), {
            ACTION: action,
            ENTITY: entity,
            TIMESTAMP: timestamp,
            UID: auth.currentUser.uid,
        });
    } else {
        setDoc(doc(db_tracking), {
            ACTION: action,
            ENTITY: entity,
            TIMESTAMP: timestamp,
            UID: null,
        });
    }
 }
