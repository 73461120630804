<template>
  <div
    class="tagChip"
    :style="{
      'color': this.highlightHex,
      'border-color': this.colorHex,
      'background': this.colorHex + '11',
    }"
  >
    <Icon :img="img" :hexCode="highlightHex" size="17" stroke="1.8" />
    <div v-if="text && !transparentTag" :class="{ textWithImg: this.img }">
      {{ text }}
    </div>
    <div v-if="text && transparentTag" :class="{ textWithImgSpace: this.img }">
      {{ text }}
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";

export default {
  name: "Tag",
  props: {
    text: String,
    color: String,
    img: String,
  },
  components: {
    Icon,
  },
  data() {
    return {
      imgSrc: "@/assets/icons/" + this.img + ".vue",
      colorHex: "#" + this.color,
      transparentTag: this.color == "ffffff",
      highlightHex: this.color != "ffffff" ? "#" + this.color : "#636363",
    };
  },
  methods: {},
  setup() {
    return {};
  },
};
</script>

<style scoped>
.tagChip {
  border-radius: 10px;
  font-size: 14px;
  color: black;
  background: #80808011;
  width: fit-content;
  border: thin solid black;
  padding: 4px 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2px;
}
.textWithImg {
  margin-left: 7px;
}
.textWithImgSpace {
  margin-left: 18px;
}
</style>
