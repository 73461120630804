<template>
  <component
    :is="img"
    :hexCode="hexCode"
    :height="Number(size)"
    :width="Number(size)"
    :stroke="stroke"
  ></component>
</template>

<script>
import sparks from "@/assets/icons/sparks.vue";
import external_link from "@/assets/icons/external_link.vue";
import clipboard from "@/assets/icons/clipboard.vue";
import linkedin_blue from "@/assets/icons/linkedin_blue.vue";
import statistics from "@/assets/icons/statistics.vue";
import measurement from "@/assets/icons/measurement.vue";
import person_card from "@/assets/icons/person_card.vue";
import computer_chip from "@/assets/icons/computer_chip.vue";
import error from "@/assets/icons/error.vue";
import arrow_left from "@/assets/icons/arrow_left.vue";
import go_right from "@/assets/icons/go_right.vue";
import building from "@/assets/icons/building.vue";
import plant from "@/assets/icons/plant.vue";
import student_hat from "@/assets/icons/student_hat.vue";
import arrow_full_up from "@/assets/icons/arrow_full_up.vue";
import arrow_full_down from "@/assets/icons/arrow_full_down.vue";

export default {
  name: "Icon",
  props: {
    text: String,
    img: String,
    hexCode: String,
    size: String,
    stroke: String,
  },
  components: {
    sparks,
    external_link,
    clipboard,
    linkedin_blue,
    statistics,
    measurement,
    person_card,
    computer_chip,
    error,
    arrow_left,
    building,
    plant,
    student_hat,
    go_right,
    arrow_full_up,
    arrow_full_down,
  },
  data() {
    return {};
  },
  methods: {},
  setup() {
    return {};
  },
};
</script>

<style scroped></style>
