<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 36 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.3301 30.5C20.4056 33.8333 15.5944 33.8333 13.6699 30.5L0.679493 8C-1.24501 4.66667 1.16062 0.5 5.00962 0.5L30.9904 0.5C34.8394 0.5 37.245 4.66667 35.3205 8L22.3301 30.5Z"
      fill="#FF0000"
    />
  </svg>
</template>
<script>
export default {
  name: "arrow_full_up",
  props: {
    height: Number,
    width: Number,
    hexCode: String,
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
  setup() {
    return {};
  },
};
</script>

<style></style>
