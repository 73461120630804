<template>
  <div class="rawLink">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "LightButton",
  props: {
    text: String,
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
  setup() {
    return {};
  },
};
</script>

<style scoped>
.rawLink {
  overflow: hidden;
  text-overflow: ellipsis;
  /* color: #721cff; */
  color: blue;
  cursor: pointer;
  font-size: 16px;
}
.rawLink:hover {
  text-decoration: underline;
}
</style>
