<template>
  <div>
    <div v-if="this.sentiment" style="color: green; margin: 10px">
      Positive Event
    </div>
    <div v-else style="color: red; margin: 10px">Negative Event</div>
    <!-- <div class="newGreyTitle" style="padding: 10px">event description</div> -->
    <div style="padding: 10px">{{ this.event.summary }}</div>
    <div class="flexLeftRow">
      <Tag
        v-for="k of this.event.keywords"
        :text="k"
        color="721cff"
        img="sparks"
        :key="k"
      />
    </div>
    <div style="padding: 10px"></div>
    <LightButton
      @click="toggleArticles()"
      :text="this.toggleTxt"
      img="clipboard"
      style="width: fit-content"
    />
    <div v-if="this.toggledArticles">
      <div v-for="l of this.event.links" :key="l" style="padding: 10px 10px">
        <div
          class="flexLeftRow"
          v-if="newsDomains.find((domain) => l.includes(domain))"
        >
          <div
            style="
              width: 15px;
              height: 15px;
              overflow: hidden;
              border-radius: 5px;
              margin-right: 10px;
              border: thin solid lightgray;
            "
          >
            <img
              :src="
                require(`@/assets/news_logos/${newsDomains.find((domain) =>
                  l.includes(domain)
                )}.png`)
              "
              height="15"
              width="15"
            />
          </div>
          <div class="newGreyTitle">
            {{ newsDomains.find((domain) => l.includes(domain)) }}
          </div>
        </div>
        <div style="margin-top: 5px">
          <RawLink
            @click="openLink(l)"
            :text="
              l
                .replace('.html', '/')
                .replace(/\?.*/, '')
                .replace('?', '/')
                .replace(/([^\/])$/, '$1/')
                .split('/')
                .slice(-2, -1)[0]
                .replace(/-/g, ' ')
                .replace(/\d+$/, '')
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase";
// import db from "@/main.js";
// import { ref } from "vue";
// import Icon from "@/components/utilities/Icon.vue";
import Tag from "@/components/utilities/Tag.vue";
import RawLink from "@/components/buttons/RawLink.vue";
import LightButton from "@/components/buttons/LightButton.vue";

export default {
  name: "IndustryEvent",
  components: {
    // Icon,
    Tag,
    RawLink,
    LightButton,
  },
  props: {
    event: Object,
    sentiment: Boolean,
  },
  data() {
    return {
      newsDomains: [
        "techcrunch.com",
        "businessinsider.com",
        "economist.com",
        "theguardian.com",
        "whitehouse.gov",
        "engadget.com",
        "venturebeat.com",
        "finovate.com",
        "bankautomationnews.com",
        "theverge.com",
        "readwrite.com",
        "hbr.org",
        "finance.yahoo.com",
        "sciencedaily.com",
        "news.stanford.edu",
        "cbinsights.com",
        "forbes.com",
        "nytimes.com",
      ],
      toggledArticles: false,
      toggleTxt: "View Articles",
    };
  },
  methods: {
    openLink(link) {
      if (!link.includes("https://")) {
        link = "https://" + link;
      }
      window.open(link, "_blank");
    },
    toggleArticles() {
      this.toggledArticles = !this.toggledArticles;
      if (!this.toggledArticles) this.toggleTxt = "View Articles";
      else this.toggleTxt = "Hide Articles";
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped></style>
