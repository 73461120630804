<template>
  <div class="lunaMain">
    <div class="lunaMainResults">
      <div class="">
        <div class="flexLeftRow lunaHeaderItem">
          <div class="flexLeftRow">
            <div class="roundLogo" style="border-radius: 5px">
              <img :src="this.targetStartup.logo_url" height="30" width="30" />
            </div>
            <span class="resultFont" style="margin-left: 10px">{{
              this.targetStartup.name
            }}</span>
          </div>
        </div>
        <div class="greyTitle" style="margin: 0; margin-bottom: 5px">
          1-liner
        </div>
        <div class="flexLeftRow lunaHeaderItem">
          <div>{{ this.targetStartup.tagline }}</div>
        </div>
        <div class="greyTitle" style="margin: 0; margin-bottom: 5px">links</div>
        <div class="flexLeftRow lunaHeaderItem">
          <!-- <LightButton /> -->
          <LightButton
            @click="openLink(this.targetStartup.website)"
            :text="this.targetStartup.domain"
            img="external_link"
          />
          <LightButton
            @click="openLink(this.targetStartup.linkedin_url)"
            img="linkedin_blue"
            icon_size="16"
          />
        </div>
      </div>
    </div>
    <div class="blockTitle">
      <Icon img="computer_chip" hexCode="000000" size="18" stroke="1.5" />
      <span style="margin-left: 10px">Results</span>
    </div>
    <div class="lunaMainResults">
      <div
        class="lunaResultNum"
        :class="{
          redBox: this.lunaConfidence < 0.46,
          greenBox: this.lunaConfidence >= 0.59,
        }"
        style="padding-right: 40px"
      >
        <div class="greyTitle" style="margin: 0">
          Likelihood of Reaching Series A
        </div>
        <div class="resultFont">
          <div>
            <span v-if="this.lunaConfidence < 0.46">Low Probability</span>
            <span v-else-if="this.lunaConfidence < 0.59"
              >Medium Probability</span
            >
            <span v-else>High Probability</span>
          </div>
        </div>
        <div class="greyTitle" style="margin: 0; font-weight: 500">(alpha)</div>
      </div>
      <div class="lunaResultNum blueBox">
        <div class="greyTitle" style="margin: 0">Luna Score</div>
        <div class="flexLeftRow" style="align-items: flex-end">
          <div class="resultFont">
            {{ (this.lunaConfidence * 1000).toFixed(0) }}
          </div>
          <div
            class="greyTitle"
            style="margin: 0; font-weight: 500; padding: 0 0 4px 6px"
          >
            (0 - 1000)
          </div>
        </div>
        <div class="greyTitle" style="margin: 0; font-weight: 500">
          model: luna-1.2
        </div>
      </div>
      <!-- <div class="lunaResultNum">
        <div class="greyTitle" style="margin: 0">Market Sentiment</div>
        <div class="resultFont" v-if="this.targetStartup.industry == 'SaaS'">
          41%
        </div>
        <div class="resultFont" v-else-if="this.targetStartup.industry == 'AI'">
          17%
        </div>
        <div
          class="resultFont"
          v-else-if="this.targetStartup.industry == 'Crypto'"
        >
          16%
        </div>
        <div
          class="resultFont"
          v-else-if="this.targetStartup.industry == 'Fintech'"
        >
          32%
        </div>
        <div
          class="resultFont"
          v-else-if="this.targetStartup.industry == 'Cybersecurity'"
        >
          10%
        </div>
        <div
          class="resultFont"
          v-else-if="this.targetStartup.industry == 'Real Estate'"
        >
          12%
        </div>
        <div class="resultFont" v-else>-</div>
        <div class="greyTitle" style="margin: 0; font-weight: 500">
          model: simons-1.0
        </div>
      </div> -->
    </div>
    <!-- :img="expImg[index]" -->
    <div class="blockTitle" style="padding-bottom: 0 !important">
      <span style="margin: -5px 10px 0 10px">View more info on</span>
      <div class="flexLeftRow">
        <NavButton
          v-for="(nav, index) of [
            'Founders & Team',
            'Market',
            'Company Metrics',
          ]"
          :text="nav"
          style="width: fit-content"
          :img="outputImg[index]"
          @click="showOutput(nav)"
          :isSelected="curOutput == nav"
          :key="nav"
        />
      </div>
    </div>
    <!-- :img="expImg[index]" -->
    <!-- <div class="blockTitle">
      <Icon img="person_card" hexCode="000000" size="18" stroke="1.5" />
      <span style="margin-left: 10px">Founders</span>
    </div> -->
    <div
      class="foundList"
      v-if="
        this.loadedFounders &&
        this.founderRecap &&
        this.curOutput == 'Founders & Team'
      "
    >
      <!-- {{ this.founderRecap }} -->
      <div
        class="notableFounderBox"
        v-for="(f, index) in this.founderRecap"
        :key="index"
      >
        <div class="flexSpaceBetweenRow" style="padding: 10px">
          <div class="flexLeftRow">
            <img
              height="25"
              width="25"
              src="@/assets/images/emptyProfile.png"
            />
            <div class="notableName">{{ f.name }}</div>
          </div>
          <div class="flexRightRow">
            <LightButton
              @click="openLink(f.linkedin)"
              img="linkedin_blue"
              height="16"
              width="16"
            />
            <LightButton
              @click="showPersonBox(index)"
              text="View Experiences"
              img="clipboard"
            />
          </div>
        </div>
        <div class="flexLeftRow founderSubBox" style="background: #fafafa">
          <div v-for="recap of f.exp_recap" :key="recap">
            <Tag v-if="recap" :text="recap" color="000000" />
          </div>
        </div>
        <div
          class="flexLeftRow founderSubBox"
          v-if="f.exp_insights[0] && this.lunaConfidence > 0.52"
        >
          <div class="newGreyTitle" style="margin-right: 15px">
            distinct insights
          </div>
          <div v-for="insight of f.exp_insights" :key="insight">
            <Tag v-if="insight" :text="insight" color="721cff" img="sparks" />
          </div>
          <!-- <Tag text="test text" color="721cff" img="sparks" /> -->
        </div>
        <div class="flexLeftRow founderSubBox" v-else>
          <div class="newGreyTitle" style="margin-right: 15px">
            no distinct insights
          </div>
        </div>
      </div>
    </div>
    <!-- <Person /> -->
    <!-- <div class="blockTitle" v-if="this.curOutput == 'Metrics'">
      <Icon img="measurement" hexCode="000000" size="18" stroke="1.5" />
      <span style="margin-left: 10px">Metrics</span>
    </div> -->
    <div
      class="lunaMainResults lunaResults"
      v-if="this.curOutput == 'Company Metrics'"
      style="margin-top: 15px"
    >
      <div class="newLunaResult">
        <div class="newGreyTitle">Year Founded</div>
        <div class="resultFontSmall">
          {{ this.targetStartup.year_founded }}
        </div>
      </div>
      <div class="newLunaResult">
        <div class="newGreyTitle">Headcount</div>
        <div class="resultFontSmall">
          {{ this.targetStartup.employee_count }}
        </div>
      </div>
      <div class="newLunaResult">
        <div class="greyTitle" style="margin: 0; font-weight: 500">
          Industry
        </div>
        <div class="resultFontSmall" v-if="this.targetStartup.industry">
          {{ this.targetStartup.industry }}
        </div>
        <div class="resultFontSmall" v-else>-</div>
      </div>
      <div class="newLunaResult">
        <div class="greyTitle" style="margin: 0; font-weight: 500">Country</div>
        <div
          class="resultFontSmall"
          v-if="this.targetStartup.location.split(',').slice(-1).pop()"
        >
          {{ this.targetStartup.location.split(",").slice(-1).pop() }}
        </div>
        <div class="resultFontSmall" v-else>-</div>
      </div>
      <div class="newLunaResult">
        <div class="greyTitle" style="margin: 0; font-weight: 500">
          Last Round Raised
        </div>
        <div class="resultFontSmall" v-if="this.targetStartup.stage">
          {{ this.targetStartup.stage }}
        </div>
        <div class="resultFontSmall" v-else>-</div>
      </div>
    </div>
    <div
      class="lunaMainResults lunaResults"
      v-if="this.curOutput == 'Company Metrics'"
    >
      <div class="newLunaResult">
        <div class="greyTitle" style="margin: 0; font-weight: 500">
          Company Funding
        </div>
        <div class="resultFontSmall" v-if="this.targetStartup.funding">
          ${{ this.targetStartup.funding }}
        </div>
        <div class="resultFontSmall" v-else>$0</div>
      </div>
    </div>
    <!-- <div class="blockTitle" v-if="this.curOutput == 'Metrics'">
      <Icon img="statistics" hexCode="000000" size="18" stroke="1.5" />
      <span style="margin-left: 10px">Market Statistics (2023)</span>
    </div> -->
    <div
      class="lunaMainResults lunaResults"
      v-if="
        this.curOutput == 'Market' &&
        this.listOfIndustries.includes(this.targetStartup.industry)
      "
      style="margin-top: 15px"
    >
      <div class="newLunaResult">
        <div class="greyTitle" style="margin: 0; font-weight: 500">
          Industry
        </div>
        <div class="resultFontSmall" v-if="this.targetStartup.industry">
          {{ this.targetStartup.industry }}
        </div>
        <div class="resultFontSmall" v-else>-</div>
      </div>
      <div class="newLunaResult">
        <div class="greyTitle" style="margin: 0; font-weight: 500">
          Total Number of Series A
        </div>
        <div
          class="resultFontSmall"
          v-if="
            this.targetStartup.industry &&
            this.seriesAamount[this.targetStartup.industry]
          "
        >
          {{ this.seriesAamount[this.targetStartup.industry] }}
        </div>
        <div class="resultFontSmall" v-else>-</div>
      </div>
      <div class="newLunaResult">
        <div class="greyTitle" style="margin: 0; font-weight: 500">
          Average Series A Raised
        </div>
        <div
          class="resultFontSmall"
          v-if="
            this.targetStartup.industry &&
            this.seriesAaverage[this.targetStartup.industry]
          "
        >
          ${{ this.seriesAaverage[this.targetStartup.industry] }}M
        </div>
        <div class="resultFontSmall" v-else>-</div>
      </div>
      <div class="newLunaResult">
        <div class="greyTitle" style="margin: 0; font-weight: 500">
          Total Capital Raised
        </div>
        <div
          class="resultFontSmall"
          v-if="
            this.targetStartup.industry &&
            this.marketRaisedCap[this.targetStartup.industry]
          "
        >
          ${{ this.marketRaisedCap[this.targetStartup.industry] }}B
        </div>
        <div class="resultFontSmall" v-else>-</div>
      </div>
      <div class="newLunaResult">
        <div class="greyTitle" style="margin: 0; font-weight: 500">
          Total Number of Deals
        </div>
        <div
          class="resultFontSmall"
          v-if="
            this.targetStartup.industry &&
            this.marketNumDeals[this.targetStartup.industry]
          "
        >
          {{ this.marketNumDeals[this.targetStartup.industry] }}
        </div>
        <div class="resultFontSmall" v-else>-</div>
      </div>
    </div>
    <!-- <div class="blockTitle" v-if="this.targetStartup.linkedin_id != 93605691">
      <Icon img="person_card" hexCode="000000" size="18" stroke="1.5" />
      <span style="margin-left: 10px">Team Breakdown</span>
    </div> -->
    <h2
      v-if="
        !this.teamError &&
        this.targetStartup.linkedin_id != 93605691 &&
        this.curOutput == 'Founders & Team'
      "
      style="text-align: left; width: 847px; padding: 20px 0 0 10px"
    >
      Team Breakdown
    </h2>
    <div
      class="flexLeftRow flexWrap"
      style="max-width: 847px; min-width: 847px"
      v-if="
        !this.teamError &&
        this.targetStartup.linkedin_id != 93605691 &&
        this.curOutput == 'Founders & Team'
      "
    >
      <div
        v-for="t of [
          'Product & Technology',
          'Finance & Operations',
          'Growth & Sales',
          'Other',
        ]"
        :key="t"
      >
        <TeamTile
          v-if="teamList && lengthFilteredTeam(this.teamList, t) > 0"
          :teamType="t"
          :teamList="filterTeamList(this.teamList, t)"
          @click="openTeam(this.teamList, t)"
        />
        <TeamTile v-else-if="!teamList" :teamType="t" :teamList="null" />
      </div>
    </div>
    <div
      v-else-if="
        this.targetStartup.linkedin_id != 93605691 &&
        this.curOutput == 'Founders & Team'
      "
      style="padding-top: 20px"
    >
      We're sorry, this feature is currently not available
    </div>
    <div
      v-if="
        this.curOutput == 'Market' &&
        this.listOfIndustries.includes(this.targetStartup.industry)
      "
      class="blockTitle"
    >
      <CandleGraph
        :labels="this.labelsForSentiment"
        :data="this.dataForSentiment"
        :title="this.targetStartup.industry + ' sentiment over time'"
        @changeCandleFocus="changeIndustryYear"
      />
    </div>
    <div
      v-if="
        this.curOutput == 'Market' &&
        this.industryExplanation.length != 0 &&
        this.listOfIndustries.includes(this.targetStartup.industry)
      "
    >
      <!-- {{ this.labelsForSentiment[this.focusedIndustryYear] }} -->
      <!-- {{ this.industryInfo[this.focusedIndustryYear].explaination }} -->
      <h2 style="margin-top: 20px">
        {{ this.industryInfo[this.focusedIndustryYear].year }}
        Notable {{ this.targetStartup.industry }} Events
      </h2>
      <div style="margin: 5px; font-size: 14px">
        Note: a given year can have mainly negative events while still being
        overall a positive year
      </div>
      <div
        v-for="e of this.industryExplanation"
        :key="e"
        class="blockTitle"
        :class="{
          'greenBack':
            this.industryInfo[
              this.focusedIndustryYear
            ].explaination.positive.includes(e),
          'redBack':
            !this.industryInfo[
              this.focusedIndustryYear
            ].explaination.positive.includes(e),
        }"
      >
        <IndustryEvent
          :event="e"
          :sentiment="
            this.industryInfo[
              this.focusedIndustryYear
            ].explaination.positive.includes(e)
          "
        />
      </div>
    </div>
    <div
      style="margin-top: 20px"
      v-else-if="
        this.curOutput == 'Market' &&
        this.listOfIndustries.includes(this.targetStartup.industry)
      "
    >
      No significant notable events
    </div>
    <div v-else-if="this.curOutput == 'Market'" style="margin-top: 20px">
      We're sorry, {{ this.targetStartup.industry }} is an unsupported industry
    </div>
    <div style="height: 60px"></div>
    <Person
      @closePersonBox="closePersonBox"
      v-if="isShowingPerson"
      :person="this.selectedPerson"
      :recap="this.selectedRecap"
      :companyName="this.targetStartup.name"
    />
    <Team
      v-if="isShowingTeam"
      :chosenTeamList="filterTeamList(this.chosenTeamList, this.chosenTeam)"
      :chosenTeam="chosenTeam"
      @closeTeam="closeTeam"
    />
  </div>
</template>

<script>
// import firebase from "firebase";
import { db } from "@/main.js";
import { getDoc, doc } from "@firebase/firestore";
// import { ref } from "vue";
import axios from "axios";
// import logAction from "@/trackers.js";
import Tag from "@/components/utilities/Tag.vue";
import LightButton from "@/components/buttons/LightButton.vue";
import NavButton from "@/components/buttons/NavButton.vue";
import Icon from "@/components/utilities/Icon.vue";
import CandleGraph from "@/components/graphs/CandleGraph.vue";
import Person from "@/views/popupViews/luna/Person.vue";
import Team from "@/views/popupViews/luna/Team.vue";
import TeamTile from "@/components/designs/TeamTile.vue";
import IndustryEvent from "@/components/designs/IndustryEvent.vue";

export default {
  name: "Output",
  props: {
    targetStartup: Object,
    // targetFunding: Number,
    // targetLocation: String,
    // targetRevenue: Number,
    // targetStage: Number,
    // targetIndustry: Number,
    lunaConfidence: Number,
    stage: Object,
    industryCipher: Object,
    dataForModel: Object,
    wholeStartup: Object,
    apiKey: String,
  },
  components: {
    // Person,
    Tag,
    Icon,
    LightButton,
    Person,
    TeamTile,
    Team,
    NavButton,
    CandleGraph,
    IndustryEvent,
  },
  data() {
    return {
      loadedFounders: false,
      listOfIndustries: [
        "SaaS",
        "AI",
        "Crypto",
        "Fintech",
        "Cybersecurity",
        "Biotech",
        "HealthTech",
      ],
      seriesAamount: {
        "Fintech": 495,
        "AI": 520,
        "Crypto": 153,
        "Cybersecurity": 132,
        "Marketing": 170,
        "SaaS": 1543,
      },
      seriesAaverage: {
        "Fintech": 13.6,
        "AI": 17.3,
        "Crypto": 14.9,
        "Cybersecurity": 15.7,
        "Marketing": 10.5,
        "SaaS": 14.4,
      },
      marketRaisedCap: {
        "Fintech": 418,
        "AI": 97,
        "Crypto": 12,
        "Cybersecurity": 30,
        "Marketing": 77,
        "SaaS": 325,
      },
      marketNumDeals: {
        "Fintech": "8,162",
        "AI": "5,659",
        "Crypto": "2,160",
        "Cybersecurity": "1,400",
        "Marketing": "2,452",
        "SaaS": "18,472",
      },
      outputImg: ["person_card", "statistics", "measurement"],
      curOutput: "Founders & Team",
      dataForSentiment: [],
      labelsForSentiment: [],
      industryInfo: null,
      industryExplanation: [],
      // founderRecap: await this.recapFounder(),
      founderRecap: null,
      teamList: null,
      isShowingPerson: false,
      isShowingTeam: false,
      selectedPerson: null,
      selectedRecap: null,
      chosenTeamList: null,
      teamError: false,
      focusedIndustryYear: 9,
    };
  },
  async mounted() {
    this.industryInfo = await this.getIndustry();
    // console.log(this.industryInfo);
    if (this.industryInfo.length != 0) {
      this.industryExplanation = [
        ...this.industryInfo[9].explaination.positive,
        ...this.industryInfo[9].explaination.negative,
      ]
        .filter((item) => item.single_incident)
        .sort((a, b) => b.cluster_size - a.cluster_size);
    }

    // console.log(this.industryExplanation);
    this.founderRecap = await this.recapFounder();
    this.teamList = await this.getTeamList();
  },
  async beforeUpdate() {
    // this.founderRecap = await this.recapFounder();
  },
  methods: {
    showOutput(n) {
      this.curOutput = n;
    },
    openLink(link) {
      if (!link.includes("https://")) {
        link = "https://" + link;
      }
      window.open(link, "_blank");
    },
    closePersonBox() {
      this.isShowingPerson = false;
      this.selectedPerson = null;
      this.selectedRecap = null;
    },
    showPersonBox(i) {
      this.isShowingPerson = true;
      this.selectedPerson = this.dataForModel.founder[i];
      this.selectedRecap = this.founderRecap[i];
    },
    changeIndustryYear(i) {
      // console.log("hey");
      this.focusedIndustryYear = i;
      this.industryExplanation = [
        ...this.industryInfo[i].explaination.positive,
        ...this.industryInfo[i].explaination.negative,
      ]
        .filter((item) => item.single_incident)
        .sort((a, b) => b.cluster_size - a.cluster_size);
    },
    closeTeam() {
      this.isShowingTeam = false;
      this.chosenTeamList = null;
      this.chosenTeam = null;
    },
    openTeam(list, type) {
      this.isShowingTeam = true;
      this.chosenTeamList = list;
      this.chosenTeam = type;
    },
    lengthFilteredTeam(list, type) {
      const returnedList = this.filterTeamList(list, type);

      return returnedList.length;
    },
    filterTeamList(list, type) {
      var filteredList = [];
      for (let p of list) {
        // console.log(p);
        if (p.department == type) {
          filteredList.push(p);
        }
      }
      return filteredList;
    },
    toggleFounder(i) {
      this.founderIsHidden[i] = !this.founderIsHidden[i];
    },
    async getFounderInsights(count) {
      // return this.wholeStartup.FOUNDER_INSIGHTS[count];
      if (this.wholeStartup.FOUNDER_INSIGHTS)
        return this.wholeStartup.FOUNDER_INSIGHTS[count];
      else return "";
    },
    async getIndustry() {
      const t_ref = doc(db, "industries", this.targetStartup.industry);
      const t_doc = await getDoc(t_ref);
      let return_obj = [];

      if (!t_doc.data()) {
        return [];
      }

      for (let o of t_doc.data().SIMONS) {
        let list_o = JSON.parse(o);
        return_obj.push(list_o);

        this.labelsForSentiment.push(list_o.year);
        let dualItem = [
          list_o.market_sentiment.positive,
          list_o.market_sentiment.negative,
        ];
        this.dataForSentiment.push(dualItem);
      }

      return return_obj;
      // console.log(obj);
      // console.log(obj.market_sentiment.positive);
    },
    async getTeamList() {
      const options = {
        method: "POST",
        url: "https://go-data-api.fly.dev/companies/scrape_employees",
        headers: {
          "Authorization": this.apiKey,
        },
        data: {
          linkedin_company_id: this.targetStartup.linkedin_id,
          linkedin_company_name: this.targetStartup.name,
        },
      };
      try {
        const response = await axios.request(options);
        this.startupFounders = [];
        this.potentialFounders = [];
        this.teamError = false;

        return response.data.employees;
      } catch (error) {
        this.teamError = true;
        console.error(error);
      }
    },
    async recapFounder() {
      let founderRecaps = [];
      const educationLevels = ["Bachelor's", "Master's", "PhD"];
      let count = 0;

      for (let founder of this.dataForModel.founder) {
        // let faang = "";
        // let big4 = "";
        // let mbb = "";
        // let major_exp = "";

        let founderCount = "";
        if (founder.founding_experience.length > 0)
          founderCount = founder.founding_experience.length + "x Founder";

        // if (founder.educationLevels)
        let highestEducationInt = founder.education_experience
          .filter((item) => item.school.world_rank != 9999)
          .reduce((max, obj) => Math.max(max, obj.degree_level), -Infinity);

        let highestEducation = educationLevels[highestEducationInt - 1];
        let leadership = "";

        let leadership_count = 0;
        for (let exp of founder.work_experience) {
          var regex = /C[A-Z]O/;

          if (
            regex.test(exp.position_title) ||
            (exp.position_title.toLowerCase().includes("chief") &&
              exp.position_title.toLowerCase().includes("officer")) //||
            // (exp.position_title.toLowerCase().includes("president") &&
            //   !exp.position_title.toLowerCase().includes("vice"))
          ) {
            leadership_count++;
          }

          // // Facebook (now Meta Platforms), Amazon, Apple, Netflix and Google (now Alphabet)
          // const faang_id = [
          //   "76987811",
          //   "1586",
          //   "162479",
          //   "165158",
          //   "165158",
          //   "1441",
          // ];

          // //McKinsey, BCG, Bain
          // const mbb_id = ["1371", "1784", "2114"];

          // //Deloitte, EY, KPMG, PwC
          // const big4_id = ["1038", "1073", "2525300", "1044"];

          // if (faang_id.includes(exp.company.linkedin_id)) faang = "ex-FAANG";
          // else if (mbb_id.includes(exp.company.linkedin_id)) mbb = "ex-MBB";
          // else if (big4_id.includes(exp.company.linkedin_id)) big4 = "ex-Big4";
          // else if (exp.company.company_value > 9)
          //   major_exp = "Experience at major companies";
        }
        if (leadership_count > 0) leadership = leadership_count + "x C-suite";

        let exp_insights = await this.getFounderInsights(count);

        if (founder.profile_id == "844057358")
          exp_insights = "Technical Development";
        else if (founder.profile_id == "545641876")
          exp_insights = "Network Building";
        else if (founder.profile_id == "706949230")
          exp_insights = "Data Science & AI, Academic Research";
        else if (founder.profile_id == "788194810")
          exp_insights =
            "Serial Entrepreneurship, Software Engineering, Product Management";
        else if (founder.profile_id == "776913147")
          exp_insights = "Strategic Social Impact, Market Expansion";

        let singleRecap = {
          name: founder.name,
          linkedin: founder.links,
          exp_recap: [
            leadership,
            founderCount,
            highestEducation,
            // faang,
            // mbb,
            // big4,
            // major_exp,
          ],
          exp_insights: exp_insights.split(", "),
          // faang: faang,
          // mbb: mbb,
          // big4: big4,
          // major_exp: major_exp,
        };

        founderRecaps.push(singleRecap);

        count++;
      }
      // this.loadedFounders = false;
      this.loadedFounders = true;
      return founderRecaps;
    },
  },
  setup() {
    return {};
  },
};
</script>

<style>
.loadingModel {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.experienceItem {
  border-bottom: thin solid #9595953f;
  padding: 10px;
}
.experienceTitle {
  margin: 0;
  padding: 7px 5px;
  border-bottom: thin solid #9595953f;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.experienceBox {
  background: #95959511;
}
.founderHeader {
  border-bottom: thin solid #9595953f;
  padding: 10px;
}
.founderLunaBox {
  display: flex;
  flex-direction: column;
  /* padding: 20px; */
  /* margin: 10px; */
  /* margin-right: 0; */
  margin-top: 5px;
  width: 415px;
  border: thin solid #9595953f;
  border-radius: 7px;
  height: fit-content;
  overflow: hidden;
}
.notableFounderBox {
  width: 100%;
  margin-top: 10px;
  border-radius: 10px;
  border: thin solid #9595953f;
}
.notableName {
  margin-left: 10px;
  font-weight: bold;
  /* text-decoration: underline; */
}
.notableExp {
  /* border-top: thin solid #9595953f; */
  padding: 10px;
  padding-top: 0;
}
.notableUniqueExp {
  padding: 5px;
  padding-top: 25px;
}
.notableTitle {
  width: calc(100% - 20px);
  /* border-bottom: thin solid #9595953f; */
  /* background: #95959511; */
  padding: 15px 5px 0 5px;
  padding-bottom: 0;
  font-weight: bold;
}
.foundList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  /* -webkit-column-count: 2; */
  /* -moz-column-count: 2; */
  /* column-count: 2; */
  /* -webkit-column-gap: 5px; */
  /* -moz-column-gap: 5px; */
  /* column-gap: 5px; */
  width: 840px;
  /* margin-bottom: 40px; */
}
.resultFontSmall {
  margin: 0;
  /* font-size: 25px; */
  font-size: 20px;
  margin-top: 5px;
}
.lunaResults {
  display: flex;
  /* justify-content: space-between !important; */
  /* justify-content: flex-start; */
  /* align-items: flex-start; */
  /* width: 100%; */
  margin-left: 15px;
}
.paddedRow {
  padding: 7px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.lunaResult {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: thin solid #9595953f;
  border-radius: 7px;
  margin-bottom: 2px;
  min-width: 230px;
  width: calc(33% - 20px);
  height: 45px;
  padding: 0 10px;

  /* min-width: 100px; */
  /* border: thin solid #9595953f; */
  /* border-radius: 7px; */
  /* padding: 5px; */
  /* margin: 10px 20px 10px 0; */
}
.newLunaResult {
  width: 20%;
  min-width: 100px;
}
.founderSubBox {
  padding: 10px;
  border-top: thin solid #9595953f;
  min-height: 29px;
  flex-wrap: wrap;
}
.greenBack {
  background: #0080001c;
  border-color: green;
}
.redBack {
  background: #ff00001c;
  border-color: red;
}
</style>
