<template>
  <div id="termsBody">
    <div class="tcTitle">Disclaimer</div>
    <div class="tcText">
      Moonarch provides tools and data analysis to assist users in evaluating
      potential investment opportunities. However, Moonarch does not provide
      legal advice, financial advice, tax advice, or investment advice, nor does
      it provide recommendations regarding the buying or selling of securities.
    </div>
    <div class="tcSubtitle">No Legal Advice</div>
    <div class="tcText">
      The information and tools provided by Moonarch are not intended to be a
      substitute for professional legal advice. Users should consult with a
      qualified legal professional for any legal questions or concerns.
    </div>
    <div class="tcSubtitle">No Financial or Tax Advice</div>
    <div class="tcText">
      The information provided by Moonarch is not intended to be financial or
      tax advice. Users should seek advice from a qualified financial or tax
      advisor for any financial or tax-related questions or concerns.
    </div>
    <div class="tcSubtitle">No Investment Advice</div>
    <div class="tcText">
      Moonarch is not an investment advisor and is not registered with any
      regulatory authority as an investment advisor. The tools and information
      provided by Moonarch are for informational purposes only and should not be
      construed as an endorsement or recommendation for any specific investment.
      Users should consult with a qualified investment professional before
      making any investment decisions.
    </div>
    <div class="tcSubtitle">No Advice on Buying or Selling Securities</div>
    <div class="tcText">
      Moonarch does not provide advice on the buying or selling of securities.
      Any decisions regarding investments in securities should be made based on
      the user's own judgment and in consultation with a qualified investment
      professional.
    </div>
    <div class="tcSubtitle">No Warranty</div>
    <div class="tcText">
      Moonarch makes no representations or warranties as to the accuracy,
      completeness, or timeliness of the information provided. The information
      and tools are provided "as is" without warranty of any kind, either
      express or implied.
    </div>
    <div class="tcSubtitle">Third-Party Information</div>
    <div class="tcText">
      Moonarch may provide links to third-party websites or information. These
      links are for convenience only and do not constitute an endorsement.
      Moonarch is not responsible for the content of any linked third-party
      sites.
    </div>
    <div class="tcSubtitle">Regulatory Compliance</div>
    <div class="tcText">
      Moonarch is committed to operating within the bounds of applicable laws
      and regulations. The information and tools provided are structured to
      avoid any need for regulatory registration.
    </div>
    <div class="tcSubtitle">Use of Information</div>
    <div class="tcText">
      The information and tools provided by Moonarch are intended for general
      informational purposes only. Users are responsible for their own
      investment decisions and should perform their own due diligence.
    </div>
    <div class="tcSubtitle">Liability Limitation</div>
    <div class="tcText">
      By using Moonarch, you acknowledge and agree that Moonarch shall not be
      liable for any direct, indirect, incidental, consequential, or any other
      type of damages arising out of or in connection with the use of the
      information and tools provided.
    </div>
    <div class="tcSubtitle">Personal Responsibility</div>
    <div class="tcText">
      Users are solely responsible for their investment decisions and should
      take into account their individual financial situation, investment
      objectives, and risk tolerance.
    </div>
    <div class="tcText">
      By using Moonarch, you acknowledge and agree to this disclaimer and
      understand that Moonarch is not responsible for any decisions made based
      on the information provided. The use of Moonarch’s tools and information
      is at your own risk.
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsConditions",
  components: {},
  methods: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
#termsBody {
  width: 100%;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  font-family: sans-serif;
}
.tcTitle {
  font-weight: bold;
  font-size: 20px;
  margin: 20px;
}
.tcSubtitle {
  font-weight: bold;
  font-size: 15px;
  margin: 20px;
}
.tcText {
  font-size: 15px;
  margin: 20px;
}
</style>
