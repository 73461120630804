<template>
  <div id="teamTile">
    <div>
      <div class="notableName" style="margin: 0">{{ teamType }}</div>
      <div
        v-if="!this.teamList"
        class="loadingTextItem"
        style="width: 100px; margin-top: 10px"
      ></div>
      <div v-else class="newGreyTitle" style="margin-top: 7px">
        {{ this.teamList.length }} team members
      </div>
    </div>
    <Icon img="go_right" hexCode="000000" size="18" stroke="2" />
  </div>
</template>

<script>
// import firebase from "firebase";
// import db from "@/main.js";
// import { ref } from "vue";
import Icon from "@/components/utilities/Icon.vue";

export default {
  name: "TeamTile",
  components: {
    Icon,
  },
  props: {
    teamType: String,
    teamList: Object,
  },
  data() {
    return {};
  },
  methods: {},
  setup() {
    return {};
  },
};
</script>

<style scoped>
#teamTile {
  width: 254px;
  border: thin solid #9595953f;
  border-bottom: 2px solid #9595953f;
  border-radius: 7px;
  margin: 6px 3px 3px 3px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
#teamTile:hover {
  background: #efefef;
}
#teamTile:active {
  border: thin solid #9595953f;
  margin-top: 7px;
}
</style>
