import { createApp } from 'vue';
import App from './App.vue';
// import firebase from 'firebase/app';
import { initializeApp } from '@firebase/app';
import { getAuth } from '@firebase/auth';
import { getFirestore } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';
import { createRouter, createWebHistory } from 'vue-router';
// import LoginView from './views/LoginView.vue';
import Login from './views/pages/Login.vue';
import Luna from './views/pages/Luna.vue';
// import Error404 from './components/utilities/404.vue';

var firebaseConfig = {
    apiKey: "AIzaSyDcP0Y8XwUTyMZA7YFOhPfmS7FyO8Phmys",
    authDomain: "moonarch-portal.firebaseapp.com",
    projectId: "moonarch-portal",
    storageBucket: "moonarch-portal.appspot.com",
    messagingSenderId: "810813954667",
    appId: "1:810813954667:web:b10cf75110e1f0a4d2ef2e",
    measurementId: "G-L90H3Q1ZBC"
};

const searchParams = new URLSearchParams(window.location.search);

const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        name: 'Login',
        meta: { 
          requiresAuth: false,
          signup: searchParams.has("signup") && searchParams.get("signup")
         },
        component: Login,
      },
      {
        path: '/luna', //:subdomain/portal
        name: 'Luna',
        meta: { requiresAuth: true },
        component: Luna,
        // children: [
        //   {
        //     path: ':companyID?', // The dynamic parameter ':listID'
        //     name: 'Luna',
        //     component: Luna,
        //     props: { listContent: true }
        //   },
        // ],
      },
      // {
      //   path: '/luna', //:subdomain/portal
      //   name: 'Luna',
      //   meta: { requiresAuth: true },
      //   component: Luna,
      //   children: [
      //     {
      //       path: ':companyID', // The dynamic parameter ':listID'
      //       name: 'Luna',
      //       component: Luna,
      //       props: { listContent: true }
      //     },
      //   ],
      // },
      // {
      //   path: '/luna', //:subdomain/portal
      //   name: 'Luna',
      //   meta: { requiresAuth: true },
      //   component: Luna,
      // },
      // {
      //   path: "/:catchAll(.*)",
      //   name: 'Error404',
      //   component: Error404,
      // },
    ],
  });

  router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
      if (auth.currentUser) {
        next();
        return;
      } else {
        next({ 
          name: 'Login',
          query: { redirect: to.fullPath, 
            signup: searchParams.has("signup") && searchParams.get("signup") == "true" ? true : false
          },
        });
        return;
      }
    
    } else {
      next();
      return;
    }
  });

  const defaultTitle = 'Portal';
  router.afterEach((to) => {
    document.title = 'Moonarch | ' + to.name || defaultTitle;
  });

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
const firebase_app = initializeApp(firebaseConfig);
// const db = firebase.firestore();
const db = getFirestore(firebase_app);
const auth = getAuth(firebase_app);
const storage = getStorage(firebase_app);

const app = createApp(App);
app.use(router);
app.mount('#app');

export {db, auth, storage };