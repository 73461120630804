<template>
  <svg
    :width="width"
    :height="height"
    :color="hexCode"
    viewBox="0 0 800 800"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#721cff"
      d="M100 0C44.7715 0 0 44.7715 0 100V700C0 755.228 44.7715 800 100 800H700C755.228 800 800 755.228 800 700V100C800 44.7715 755.228 0 700 0H100ZM248.83 664.619H130.138V307.527H248.83V664.619ZM189.484 258.789H188.7C148.884 258.789 123.077 231.358 123.077 197.073C123.077 162.034 149.639 135.381 190.261 135.381C230.892 135.381 255.861 162.034 256.638 197.073C256.638 231.358 230.892 258.789 189.484 258.789ZM676.923 664.619H558.2V473.574C558.2 425.589 541.046 392.835 498.077 392.835C465.261 392.835 445.76 414.889 437.169 436.251C434.047 443.882 433.231 454.49 433.231 465.19V664.621H314.484C314.484 664.621 316.076 341.029 314.484 307.529H433.231V358.129C449.007 333.853 477.162 299.146 540.23 299.146C618.361 299.146 676.923 350.17 676.923 459.845V664.619Z"
    />
  </svg>
</template>
<script>
export default {
  name: "linkedin_blue",
  props: {
    height: Number,
    width: Number,
    hexCode: String,
    stroke: String,
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
  setup() {
    return {};
  },
};
</script>

<style></style>
