<template>
  <div class="buttonLight">
    <!-- <img :src="imgSrc" width="15" height="15" :color="colorHex" /> -->
    <Icon :img="img" :hexCode="colorHex" :size="s" stroke="2" />
    <div v-if="text" :class="{ textWithImg: this.img }">{{ text }}</div>
  </div>
</template>

<script>
import Icon from "../utilities/Icon.vue";

export default {
  name: "LightButton",
  props: {
    text: String,
    img: String,
    icon_size: String,
  },
  components: {
    Icon,
  },
  data() {
    return {
      imgSrc: "@/assets/icons/" + this.img + ".vue",
      colorHex: "#000000",
      s: this.icon_size ? this.icon_size : "18",
    };
  },
  methods: {},
  setup() {
    return {};
  },
};
</script>

<style scoped>
.buttonLight {
  border-radius: 7px;
  cursor: pointer;
  border-bottom: thin solid #dadada;
  font-size: 14px;
  padding: 5px 7px;
  margin: 2px;
  min-height: 18px;
  min-width: 14px;
  outline: thin solid #dadada;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}
.buttonLight:hover {
  background: #efefef;
}
.buttonLight:active {
  border-bottom: none;
  margin-top: 3px;
}
.textWithImg {
  margin-left: 10px;
}
</style>
